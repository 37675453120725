import React, { useEffect, useState } from 'react'
import { useEthers, shortenAddress, useLookupAddress } from '@usedapp/core'
import Button from 'react-bootstrap/Button';
import styled from 'styled-components'
import Web3Modal from 'web3modal';
import { DeFiWeb3Connector } from "deficonnect";
import { AccountModal } from './AccountModal';
import WalletConnectProvider from '@deficonnect/web3-provider';
import WCProvider from "@walletconnect/web3-provider"
import cdcLogo from '../images/defiwallet.svg';

export const Web3ModalButton = () => {
  const { account, activate, deactivate } = useEthers()
  const ens = useLookupAddress()
  const [showModal, setShowModal] = useState(false)
  const [activateError, setActivateError] = useState('')
  const { error } = useEthers()
  useEffect(() => {
    if (error) {
      setActivateError(error.message)
    }
  }, [error])

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: 'Metamask',
          description: 'Connect with the provider in your Browser',
        },
        package: null,
      },
      walletconnect: {
        package: WCProvider,
        display: {
          name: 'CDC Defi Wallet on iOS'
        },
        options: {
          rpc: {
            25: "https://gateway.nebkas.ro",
            338: "https://evm-t3.cronos.org"
          },
          chainId: 25,
          network: 'cronos'
        },
      },
      "custom-defiwallet": {
        display: {
          logo: cdcLogo,
          name: "Crypto.com DeFi Wallet",
          description: "Connect with the CDC DeFi Wallet"
        },
        options: {},
        package: WalletConnectProvider,
        connector: async (ProviderPackage, options) =>  {
          const connector = new DeFiWeb3Connector({
            supportedChainIds: [25],
            rpc: {25: 'https://gateway.nebkas.ro'},
            pollingInterval: 15000,
            metadata: {
              icons: ['https://lootpad.io/images/logo.svg'],
              description: "LootPad NFT Launchpad"
            }
          });

          await connector.activate();
          let provider = await connector.getProvider();
          return provider;
        }
      }
    }

    const web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions
    })

    const provider = await web3Modal.connect()

    if (provider.wc) {
      provider.wc._networkId = 25
      provider.wc._chainId = 25
      provider.wc._rpcUrl = 'https://gateway.nebkas.ro'
    }

    await provider.enable();
    await activate(provider)

  }

  return (
    <Account>
      {/* <ErrorWrapper>{activateError}</ErrorWrapper> */}
      {showModal && <AccountModal setShowModal={setShowModal} />}
      {account ? (
        <>
          <AccountLabel onClick={() => setShowModal(!showModal)}>{ens ?? shortenAddress(account)}</AccountLabel>
          <LoginButton onClick={() => deactivate()}>Disconnect</LoginButton>
        </>
      ) : (
        <LoginButton onClick={activateProvider}>Connect</LoginButton>
      )}
    </Account>
  )
}

const ErrorWrapper = styled.div`
  color: #ff3960;
  margin-right: 40px;
  margin-left: 40px;
  overflow: auto;
`

const Account = styled.div`
  margin-left:0px;
`

const LoginButton = styled(Button)`
  background-color: blue;
`

const AccountLabel = styled(Button)`
  height: 32px;
  margin-right: -40px;
  padding-right: 40px;
  padding-left: 8px;
  background-color: blue;
  font-size: 12px;
`