import Modal from 'react-bootstrap/Modal';

export default function ViewerModal({ show, handleClose, nft }) {

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      {/* <Modal.Header closeButton>
        <Modal.Title>{nft.name}</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        {nft.video
        ? <video style={{width:'100%'}} controls loop autoPlay src={nft.video}></video>
        : <img style={{width:'100%'}} src={nft.image}/>
        }
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer> */}
    </Modal>
  )
}