import { useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useEthers } from '@usedapp/core';
import { Web3ModalButton } from '../components/Web3ModalButton'
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown'

export default function TopBar() {
  const { account, deactivate } = useEthers()
  const navigate = useNavigate()

  useEffect(() => {
    console.log('account is', account)
  }, [account])

  return (
    <Navbar bg="dark" variant="dark" expand="lg" style={{zIndex: 99999}}>
      <Container>
        <Navbar.Brand style={{ cursor: 'pointer' }} onClick={ () => navigate('/') }>
          <img alt="logo" src='/images/logo.svg' height={40}/>
        </Navbar.Brand>
        {/* <Nav className="ms-auto">
          <Nav.Link href="/nfts" style={{color: '#FFF'}}>My NFTs</Nav.Link>
          <Nav.Link href="https://swap.crodex.app/#/rewards/nft" target="_blank" style={{color: '#FFF'}}>Staking</Nav.Link>
        </Nav> */}


        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={() => window.location = "https://crofundsme.org/"} style={{color: '#FFF'}}>CROFundsMe</Nav.Link>
            <Nav.Link onClick={ () => navigate('/drops') } style={{color: '#FFF'}}>Drops</Nav.Link>
            <Nav.Link onClick={ () => navigate('/nfts') } style={{color: '#FFF'}}>My NFTs</Nav.Link>
            <span>
              {!account
              ? <Web3ModalButton style={{marginLeft: '5px'}}/>
              : <Nav.Link onClick={() => deactivate()} style={{ color: '#FFF' }}>Disconnect</Nav.Link>
              }
              {/* <span class="balance">{ etherBalance && parseFloat(formatEther(etherBalance)).toFixed(2) } CRO</span> */}
            </span>
          </Nav>
        </Navbar.Collapse>


      </Container>
    </Navbar>
  )
}