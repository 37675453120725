import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useContractFunction, useEthers } from '@usedapp/core';
import { Interface } from '@ethersproject/abi';
import { Contract } from '@ethersproject/contracts';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import ContractData from '../abi';


export default function Admin() {
  const { name } = useParams();
  const [addresses, setAddresses] = useState('');
  const nftInterface = new Interface(ContractData[name].abi);
  let address = (process.env.NODE_ENV === 'development') ? ContractData[name].testnetAddress : ContractData[name].address
  let nftContract = new Contract(address, nftInterface);

  const { state, send } = useContractFunction(nftContract, "addWhiteList", { transactionName: "addWhiteList" });

  const whitelistAddresses = async () => {
    const addressesToWhitelist = addresses.replace(/(\r\n|\n|\r)/gm, "").split(',');
    console.log(addressesToWhitelist);
    await send(addressesToWhitelist)
    console.log(state);
  }

  const changeHandler = (event) => {
    setAddresses(event.target.value)
  }

  return(
    <Row>
      <textarea rows="20" value={addresses} onChange={event => changeHandler(event)}>

      </textarea>
      <Button onClick={() => whitelistAddresses()}>Add to Whitelist</Button>
    </Row>
  )
}