import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import BackButton from '../components/BackButton';
import { useNavigate } from 'react-router-dom';
import ContractData from '../abi';

let drops = [];
let completedDrops = [];
Object.keys(ContractData).map((contract, i) => {
  if (!ContractData[contract].hidden && !ContractData[contract].soldOut) {
    drops.push({ ...ContractData[contract], slug: contract })
  } else if (!ContractData[contract].hidden && ContractData[contract].soldOut) {
    completedDrops.push({ ...ContractData[contract], slug: contract })
  }
})

export default function Drops() {
  const navigate = useNavigate();

  return (
    <>
    <BackButton />
    <Row className="justify-content-center mt-1 mb-5">
      <h2 style={{textAlign: 'center'}}>Current Drops</h2>
      <Col xs={12} sm={6} md={12} lg={6}>
        <Row className="mt-4" xs={1} sm={1} md={3} lg={3}>
          {drops.map((contract, i) => (
            <Col className="mt-3" key={i}>
              <Card>
                <Card.Img style={{cursor: 'pointer'}} onClick={() => navigate(`/collection/${contract.slug}`)} variant="top" src={`/assets/${contract.slug}/${contract.thumbnail}`} />
                <Card.Body style={{cursor: 'pointer'}} onClick={() => navigate(`/collection/${contract.slug}`)}>
                  <Card.Title>{contract.title}</Card.Title>
                  <Card.Text>
                    {contract.shortDescription}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
      {/* <h2 style={{textAlign: 'center'}} className="mt-5">Completed Drops</h2>
      <Col xs={12} sm={6} md={12} lg={6}>
        <Row className="mt-4" xs={1} sm={1} md={3} lg={3}>
          {completedDrops.map((contract, i) => (
            <Col className="mt-3" key={i}>
              <Card>
                <Card.Img style={{cursor: 'pointer'}} onClick={() => navigate(`/collection/${contract.slug}`)} variant="top" src={`/assets/${contract.slug}/${contract.thumbnail}`} />
                <Card.Body>
                  <Card.Title>{contract.title}</Card.Title>
                  <Card.Text>
                    {contract.shortDescription}
                  </Card.Text>
                  <Button disabled variant="primary" onClick={() => navigate(`/collection/${contract.slug}`)}>Mint</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Col> */}
    </Row>
    </>
  )
}