
const ContractData = {
  sexypixelcows: {
    title: 'Sexy Pixel Cows',
    twitter: 'https://twitter.com/CronosKingdom',
    website: 'https://www.cronoskingdom.com',
    discord: 'https://discord.gg/tR4fS43UcT',
    address: '0xf8D969a553F2ca0a77553B02a4fCBe486A5c1735',
    testnetAddress: '0xf8D969a553F2ca0a77553B02a4fCBe486A5c1735',
    thumbnail: 'drop.jpg',
    image: 'sexypixelcows.gif',
    shortDescription: 'Cronos Kingdom brings you the Sexy Pixel Cows!',
    description: 'A collection of 10,000 manatees each with a letter that has a special purpose.',
    abi: sexypixelcows.abi,
    launchDate: 'November 20, 2022 15:00:00 UTC',
    frontpage: true,
    soldOut: false,
    hidden: false,
    max: 50,
    price: 12,
    discountPrice: 12,
    maxTokens: 10000,
    cid: 'QmNN5tHPzaCyNVgvoZF7CgxngmPb8QJWwumm7Gr8nQEFpN'
  },
  yardflamingos: {
    title: 'Yard Flamingos',
    twitter: 'https://twitter.com/YardFlamingosYF',
    website: 'https://mingosnft.com/',
    address: '0x3d38991252579f634468a28C5eFED8Ba69949d94',
    testnetAddress: '0x3d38991252579f634468a28C5eFED8Ba69949d94',
    thumbnail: 'drop.jpg',
    image: 'yardflamingos.gif',
    shortDescription: 'A collection of 25,000 uniquely generated NFTs stored on the Cronos Chain. 20 unique traits that will tell each NFT’s story. Community, Web-3, and Contests!',
    description: 'Yard Flamings is a web-3 multi chain community. Mingos will flock the block chain, launching on Cronos. Exclusive Discord P2E game for holders. 25,000 supply will allow for new members to become apart of the community and grow their flock substantially. 8 factions with 1 goal to dominate the blockchain.',
    abi: yardflamingos.abi,
    launchDate: 'November 18, 2022 19:30:00 UTC',
    frontpage: true,
    soldOut: false,
    hidden: false,
    max: 50,
    price: 10,
    discountPrice: 10,
    maxTokens: 25000,
    cid: 'QmaAz1zt1e7sgN6mJoauyge8c2Hx5A6o1bg2QnHppQRBoB'
  },
  nftbetting: {
    title: 'NFT Betting',
    twitter: 'https://twitter.com/NftBettingDapp',
    address: '0xBBB042983Fd314308dA7B1f983153047E2742051',
    discord: 'https://discord.gg/mzYbbVwh',
    testnetAddress: '0xBBB042983Fd314308dA7B1f983153047E2742051',
    thumbnail: 'drop.jpeg',
    image: 'nftbetting.gif',
    shortDescription: 'An NFT collection for an upcoming sports betting platform. Provides an opportunity to become an early investor, and earn bonuses + royalties when the platform goes live!',
    description: 'NFT Betting has come to shake up the world of sports! With our NFTs you can be part of the benefits obtained by us with sports betting, at the end of each year 18% of the profit will be distributed among the owners of the NFTs. Incredible? Well, the best is yet to come! We are working so that the owners of the NFTs can also get a higher % of benefits with the winning bets. Each NFT has a different % and that % will be added if the bet wins. You can bet both in fiat currency or Cronos, and the benefits will be distributed in CRO to the wallets of the holders. The more NFTs you have, the more % of profits you will get, join us! The bookmaker will be officially open at the end of November and December, we are waiting for you.',
    abi: nftbetting.abi,
    launchDate: 'October 23, 2022 14:30:00 UTC',
    frontpage: false,
    soldOut: false,
    hidden: true,
    max: 20,
    price: 199,
    discountPrice: 199,
    maxTokens: 7000,
    cid: 'QmPhsKY69HK5zQ9Z8emau75dAHicMrbF52cC3WQhreCxr4'
  },
  mogulproject: {
    title: 'The Mogul Project',
    twitter: 'https://twitter.com/NEWAMERICANFT',
    website: 'https://NewAmericaNFT.com',
    discord: 'https://discord.gg/nTFZ2zbDT6',
    address: '0xE864DF5F24c09662A611A86AA3dF572860b11b60',
    testnetAddress: '0xE864DF5F24c09662A611A86AA3dF572860b11b60',
    thumbnail: 'drop.png',
    image: 'mogulproject.gif',
    shortDescription: 'The Mogul Project is a Free Mint for New America NFT Holders.',
    description: 'Dracro Mogul is the older Brother of Washington Mogul. Bringing the New America NFT Comic Book to life we take a deep dive into the life of The Moguls. 30% of all proceeds goes back to New America NFT Holders.<br/>Join our Discord and get creative with the comic book story idea and earn rewards.<br/>Be a part of creating the next character in the collection.<br/>Dracro Mogul will be added to our P2E Game Kill For Cro thats scheduled to release Q1 of 2023.<br/>Beta version even sooner.',
    abi: mogulproject.abi,
    launchDate: 'October 2, 2022 16:00:00 UTC',
    frontpage: false,
    soldOut: true,
    hidden: true,
    max: 20,
    price: 360,
    discountPrice: 0,
    maxTokens: 500,
    cid: 'Qmbo9K42ffTiPhkF6eDzNam1Jbj7uL36JZEeWP9cxyYhY9'
  },
  zyzznft: {
    title: 'ZYZZ NFT',
    twitter: 'https://twitter.com/ZYZZ_NFT',
    website: 'https://linktr.ee/zyzznft',
    address: '0x6393c037e21508Eab4725dC6363D5E6dB6D538f9',
    testnetAddress: '0x6393c037e21508Eab4725dC6363D5E6dB6D538f9',
    thumbnail: 'drop.png',
    image: 'zyzznft.gif',
    shortDescription: '498 ZYZZ NFT representing the SICKCUNTS and DEFI DEGENS building an ALPHA community!!!',
    description: 'The project is a community-based project where we are hyper-focused on building a strong and inspired community. We will push message positive messages that inspire and encourage improvement in this niche crypto community. The inspiration for the NFT stems from a charismatic character that is well known In the bodybuilding community known as ZYZZ, we want the NFT to signify a character that radiates alpha and Chad-like influence across the Cronos chain. This NFT will be the cornerstone for the further expansion of the project. The team choose to remain anonymous because ZYZZ is not an individual but it is an identity; identity is an invisible force that guides your life and thats the message we want to push forward.',
    abi: zyzznft.abi,
    launchDate: 'September 20, 2022 09:00:00 UTC',
    frontpage: true,
    soldOut: false,
    hidden: false,
    max: 20,
    price: 199,
    discountPrice: 149,
    maxTokens: 498,
    cid: 'Qmawggj1CQUegM3akLNd8noDPqdnioZSsJnMSSJHZer9KL'
  },
  luckyspookies: {
    title: 'Lucky Spookies',
    website: 'https://luckyspookies.club/',
    discord: 'https://discord.gg/AEQ2BCpCNZ',
    twitter: 'https://twitter.com/LuckySpookiesPC',
    address: '0x3c47Ccb387768459D641dCe4dF556B3560a07855',
    testnetAddress: '0x3c47Ccb387768459D641dCe4dF556B3560a07855',
    thumbnail: 'drop.jpg',
    image: 'luckyspookies.gif',
    shortDescription: 'An exclusive casino chip collection of 1,000 NFTs',
    description: 'Lucky Spookies is the first Casino Chip-Utility Token ever created.<br/><br/>Our Private Club on Discord will be open to holders only and will grant access to the Casino, where members can participate in Community Events, Weekly Gold Raffle based on traits and the Monthly Platinum Raffle at the end of each month.<br/><br/>All prizes will be in $CRO or the best NFTs on the market and are related to the Token ID of the NFT, so the more you hold, the higher your chances of winning!<br/><br/><a style="color: black;" href="https://luckyspookies.club/">https://luckyspookies.club/</a>',
    abi: lucky.abi,
    launchDate: 'February 16, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: true,
    frontpage: false,
    max: 20,
    price: 88,
    discountPrice: 88,
    maxTokens: 1000,
    cid: 'QmQW6v3UdusVaAymx2RNKzhH6T3bPY5bXZDynedtkygKNy'
  },
  rosescro: {
    title: 'R.O.S.E.S: CRO',
    twitter: 'https://twitter.com/antennna7',
    website: 'https://6sense.mystrikingly.com/',
    discord: 'https://discord.com/invite/mZ9JVWpHFC',
    address: '0x3D9799D8A733D21d7A28E569eE2f20c97351fEd6',
    testnetAddress: '0x3D9799D8A733D21d7A28E569eE2f20c97351fEd6',
    thumbnail: 'drop.png',
    image: 'rosescro.gif',
    shortDescription: 'Flowers of condolence for the dead NFT projects.',
    description: '3000 roses with a beautiful gradation. Flowers of condolence for the dead NFT projects.',
    abi: rosescro.abi,
    launchDate: 'September 17, 2022 15:00:00 UTC',
    frontpage: false,
    soldOut: false,
    hidden: true,
    max: 50,
    price: 50,
    discountPrice: 20,
    maxTokens: 3000,
    cid: 'Qmeth7Kfxou7o9t5EVPzuLaCzEgFQsfHi4a4B3JpJWUeEw'
  },
  cropetlady: {
    title: 'CroPet Ladies',
    twitter: 'https://twitter.com/CroPetNFT',
    website: 'https://www.cropetnft.com/',
    discord: 'https://discord.com/invite/uMmt54cMEE',
    address: '0x57e776ebcD00650EEebd69851FB193592E8bdf13',
    testnetAddress: '0x57e776ebcD00650EEebd69851FB193592E8bdf13',
    thumbnail: 'drop.jpeg',
    image: 'cropetlady.gif',
    shortDescription: 'A collection of 150 CroPet Ladies. Collection designed to increase love for animals and help animals in animal shelters.',
    description: 'A collection of 150 CroPet Ladies. Collection designed to increase love for animals and help animals in animal shelters. 50% of profits will go to animal shelters and everyone in our Discord will be able to see that! 100% of royalties will go back to the community, that will be earned by staking. CroPet Ladies owners will get a special role on Discord. This role unlocks new channels that will grant access to exclusive giveaways, Airdrops and a special voting system where owners will be able to vote what they want for generation 2.',
    abi: cropetlady.abi,
    launchDate: 'August 27, 2022 18:00:00 UTC',
    frontpage: true,
    soldOut: false,
    hidden: false,
    max: 20,
    price: 199,
    discountPrice: 190,
    maxTokens: 150,
    cid: 'QmeDw7Y9uXKra7ewA6TyeK3tWiRKeogKsdfGPfHAUFFyXq'
  },
  ttg: {
    title: 'Tough Tiger Gang',
    twitter: 'https://twitter.com/ToughTigerGang_',
    discord: 'https://discord.gg/ASmU7AseCy',
    address: '0x66d2402c787Ca19cB30C3eE41Bc6534028DF9eBa',
    testnetAddress: '0x66d2402c787Ca19cB30C3eE41Bc6534028DF9eBa',
    thumbnail: 'drop.jpg',
    image: 'ttg.gif',
    shortDescription: 'TTG is the first community education and success oriented NFT collection on Cronos!',
    description: 'TTG is a collection of 2,420 algorithmically generated NFTs on the Cronos Network. Holding 4 NFTs will grant you a free airdrop which is your ticket to a first-of-its-kind Be Your Own Boss group. This group will pool mint proceeds together to buy and distribute high-value learning materials and information, which our holders can utilise to escape their 9-5 and become their own boss. We will also be running a buy-back program with our royalty fees, to ensure floor price support. For more information, head over to our Twitter or Discord in the links provided.',
    abi: ttg.abi,
    launchDate: 'July 30, 2022 15:00:00 UTC',
    frontpage: false,
    soldOut: false,
    hidden: true,
    max: 20,
    price: 399,
    discountPrice: 349,
    maxTokens: 2420,
    cid: 'QmSMewL5pvLKNoRstyf959eR7vXL1jJtsXupVQTH48Hauc'
  },
  cdl: {
    title: 'Cronos Demon Legion',
    twitter: 'https://twitter.com/DemonLegionCRO',
    discord: 'https://discord.gg/tgrn3t6nnd',
    address: '0xE4c7F4A8DCb74Fd531e7B65e55735e9A8E321433',
    testnetAddress: '0xE4c7F4A8DCb74Fd531e7B65e55735e9A8E321433',
    thumbnail: 'drop.png',
    image: 'cdl.gif',
    shortDescription: 'An exclusive gaming-focused, community driven collection of 555 demons.',
    description: '555 gaming-crazed demons have emerged from the void... They found themselves landed on the Cronos chain, and are looking for gamers and NFT lovers to join their Legion! Owning a Cronos Demon Legion NFT will act as an entry pass to all of our gaming events, contests, and tournaments (CRO and NFT prizes). All Demon holders will also automatically receive our Gen 2 via airdrop.',
    abi: cdl.abi,
    launchDate: 'July 12, 2022 20:30:00 UTC',
    frontpage: false,
    soldOut: true,
    hidden: true,
    max: 20,
    price: 150,
    discountPrice: 125,
    maxTokens: 555,
    cid: 'QmWkzhTs5Ky2RQt6brw4RxhfpXRpY7ihERiHMyt2S9zhys'
  },
  cbz: {
    title: 'Cronoballz',
    twitter: 'https://twitter.com/Crono_Ballz',
    discord: 'https://discord.com/invite/Xrfj7bYpRP',
    address: '0x8195Fd40c3FAf8809f6C302F46a09d6a6a3C9445',
    testnetAddress: '0x8195Fd40c3FAf8809f6C302F46a09d6a6a3C9445',
    thumbnail: 'drop.png',
    image: 'cbz.gif',
    shortDescription: 'IRL utility by sponsoring kids to play sports, Airdrops and more!',
    description: 'A 500 Baseball PFP collection inspired by my love playing and coaching the game.',
    abi: cbz.abi,
    launchDate: 'July 7, 2022 23:00:00 UTC',
    frontpage: false,
    soldOut: true,
    hidden: true,
    max: 20,
    price: 475,
    discountPrice: 475,
    maxTokens: 500,
    cid: 'Qmd76bo6MEJ52VgtLVEn98CjcqEPQbELi4FPUMecBntZpM'
  },
  ballin: {
    title: 'CBS: Ballin Bots',
    twitter: 'https://twitter.com/CronosBots',
    discord: 'discord.gg/cronosbotsociety',
    address: '0x4202074d695724366F00C24Ab9D27ac1dD2ED526',
    testnetAddress: '0x4202074d695724366F00C24Ab9D27ac1dD2ED526',
    thumbnail: 'drop.png',
    image: 'ballin.gif',
    shortDescription: 'CBS: Ballin Bots is a collection of 3,000 NFTs with striking artwork, a magnitude of traits and utility to go with it!',
    description: 'CBS: Ballin Bots is a collection of 3,000 NFTs with striking artwork, a magnitude of traits and utility to go with it!  A new era of the society is on the horizon, the Ballin Bots are currently being reprogrammed out of their hibernation chambers in order to advance the society into the future of finance…. The CBS coin, which they will personally spear head whilst the original CBS Bots carry on defending the Planet Cronos. If you are a holder of the OG Cronos Bot Society collection you will be airdropped Ballin Bots dependant on amount held (See medium article). All Royalities from secondary sales will be put back into the project to further advance the society and reward holders so dont miss out on the advancement of the Cronos Bot Society! ',
    abi: ballin.abi,
    launchDate: 'July 9, 2022 23:00:00 UTC',
    frontpage: false,
    soldOut: false,
    hidden: true,
    max: 20,
    price: 300,
    discountPrice: 250,
    maxTokens: 3000,
    cid: 'QmV7SdhXK4QJRtKxQxvAZsfDVgYUhMMF6UoaAy5usBRoPf'
  },
  croadz: {
    title: 'Croadz',
    website: 'https://croadz.io/',
    twitter: 'https://twitter.com/croadz_',
    discord: 'https://discord.gg/9ewZ4t8Pvw',
    address: '0xF02D952ddB1006FE0F84622132Ae63325452c171',
    testnetAddress: '0xF02D952ddB1006FE0F84622132Ae63325452c171',
    thumbnail: 'drop.png',
    image: 'croadz.gif',
    shortDescription: 'CROADZ are an NFT collectible that like to vibe and croak in a swamp.',
    description: 'CROADZ ARE AN NFT COLLECTIBLE FEATURING 1500 LIMITED CROADZ. THEY ARE AMPHIBIOUS CREATURES THAT LIKE TO VIBE AND CROAK IN A SWAMP. RUMOUR HAS IT CROADZ ARE BEING INVADED BY ALIENS AND MAY NEED TO FIND A WAY TO ESCAPE.',
    abi: croadz.abi,
    launchDate: 'July 9, 2022 17:00:00 UTC',
    frontpage: false,
    soldOut: false,
    hidden: true,
    max: 1,
    price: 99,
    discountPrice: 0,
    maxTokens: 1500,
    cid: 'QmfMhtHdaoK3UQYcLhWGUA7ktT9BhBMzqxAmLD84os6why'
  },
  mafia: {
    title: '🌹MAFIA',
    website: 'http://mafianftset.com',
    twitter: 'https://twitter.com/MAFIANFTSET',
    discord: 'https://discord.gg/heheUKqvm8',
    address: '0xC7294d93084Af03ecdc6a18ae9aa3EeBedA0502a',
    testnetAddress: '0xC7294d93084Af03ecdc6a18ae9aa3EeBedA0502a',
    thumbnail: 'drop.png',
    image: 'mafia.gif',
    shortDescription: '555 unique Mafia NFTs created with proven foundations from the best in the space. Welcome to the family.',
    description: '🌹 What the MAFIA offers 🌹<br><br>🪙 Partner Funded Staking on Crodex 🪙<br><br>🌴 Island Thunder funds the MAFIA staking pool with THNDR ⚡<br><br>🥩 Cronos Steakhouse funds the Safe House staking pool with STEAK 🥩<br><br>🌹 Safe House NFT Airdrop for those holding 5 MAFIA NFTs🌹<br><br>👶 Breeding to come 👶<br><br>💎 Mythic Rare Airdrop obtained by holding 6 MAFIA with different backgrounds 💎<br><br>🌹Here is your rose, welcome to the family<br><br><i>Join our Discord for all details on the roadmap and utility!</i>',
    abi: mafia.abi,
    launchDate: 'July 1, 2022 18:30:00 UTC',
    frontpage: true,
    soldOut: false,
    hidden: false,
    max: 20,
    price: 250,
    discountPrice: 225,
    maxTokens: 295,
    cid: 'QmXX1hvWYGbQDskMccbVjBmDnX5VkoX3rbzS6XXXYwS3nW'
  },
  newamerica: {
    title: 'New America NFT',
    twitter: 'https://twitter.com/NEWAMERICANFT',
    discord: 'https://discord.gg/yNJbZJSmQ5',
    address: '0x7fbbD577E8D6510f6e5Cb19c477B9A5e050D411F',
    testnetAddress: '0x7fbbD577E8D6510f6e5Cb19c477B9A5e050D411F',
    thumbnail: 'drop.jpg',
    image: 'newamerica.gif',
    shortDescription: 'New America NFT is a collection of nine different 3D uniquely designed characters.',
    description: 'With our first project on LootPad, we will launch the Enforcers. This project will consist of 1,000 NFTs with Enforcers Tiers 1-5 with 200 variations per tier. Throughout the mint process, we will airdrop combination collectables. If you collect the correct combination of characters, you will receive a female enforcer. The wallets that have the first 250 NFTs minted will receive a combination collectable airdrop. After 50% minted, the combination collectables will be random. There will be only 250 total combination collectables.<br/><br/>GAMING: We are in the first phase development of our video game. It will be a first person play-to-earn shooter game. With this game, you will be able to connect your metamask/defi wallet and play as the NFT character you hold. You will be able to play to earn in PVE and PVP mode. You will have customization options and earn 3D assets that will be compatible for game or can be sold on secondary market. Beta versions set to release early Fall.<br/><br/>UTILITIES: Holding any enforcer in your wallet will earn you 3D assets that you can use within the game or sell on the secondary markets, and also automatic whitelist to future drops. Earning a female enforcer gives you access to our DAO, automatic whitelist, and an airdrop to every future drop.<br/><br/>SPECIAL FORCES MEMBERSHIP: Another way of getting access to our DAO is through our special forces membership.  With this membership, you will earn monthly Cro rewards, you will receive a customized souvenir of your enforcer, automatic whitelist, and automatic airdrop to every future drop. Also with this membership, you will be apart of creating our third collection which will be the Enforcers Tiers 6-10. With your participation, the Special Forces community will receive 50% of first market sales.',
    abi: newamerica.abi,
    launchDate: 'July 4, 2022 14:00:00 UTC',
    frontpage: true,
    soldOut: true,
    hidden: true,
    max: 20,
    price: 550,
    discountPrice: 400,
    maxTokens: 980,
    cid: 'QmbDtu8RDBUDuDZmpLrbcQtdgMJ1qTRg8ome6aJUFojTnX'
  },
  bingo: {
    title: 'Blockchain Bingo Balls',
    website: 'https://www.blockchainbingoballs.com/',
    twitter: 'https://twitter.com/BlockBingoBall',
    discord: 'https://discord.gg/blockchainbingoballs',
    address: '0x377c98dbF280407e0344A9C30b38c5DdB5467E28',
    testnetAddress: '0x377c98dbF280407e0344A9C30b38c5DdB5467E28',
    thumbnail: 'drop.png',
    image: 'bingo.gif',
    shortDescription: 'Blockchain Bingo Balls - Main Hall PFP Collection of 1,000 NFTs',
    description: 'Blockchain Bingo Balls are proud to release the Main Hall PFP collection. A limited supply of 1000 PFPs with varying traits and rarity. Each PFP will grant its holder various utilities, similar to the OG Tilted Dabbers PFPs!',
    abi: bingo.abi,
    launchDate: 'May 23, 2022 19:00:00 UTC',
    soldOut: true,
    frontpage: false,
    max: 10,
    price: 300,
    discountPrice: 250,
    maxTokens: 1000,
    cid: 'QmbEGkzg5fduaKxSzvDGVHrYsFGC8XLa1M6SisxV5JY9uL'
  }, 
  cd2: {
    title: 'CroDoges - Story of Number One',
    website: 'https://www.comics.ly/',
    discord: 'https://discord.gg/crodogesclub',
    twitter: 'https://twitter.com/CroDogesClub',
    address: '0xdE6be615d701D8aAD6d53bB2DaAFD42f95aa0d0F',
    testnetAddress: '0xdE6be615d701D8aAD6d53bB2DaAFD42f95aa0d0F',
    thumbnail: 'drop.gif',
    image: 'cd2.gif',
    shortDescription: 'The comic book "Story of Number 1" published in an edition of 1111 copies.',
    description: 'COMICSLY PRESENTS...<br/><br/>...the 1st edition of the comic book "Story of Number 1" published in an edition of 1111 copies.<br/><br/>The first collection features the story of Crodoges most controversial hero.<br/><br/>IT IS UP TO YOU WHETHER YOU LEAVE THE COMIC WRAPPED IN OPAQUE FILM OR UNWRAP IT AND CHECK THE RARITY:<br/><br/>Common - black and white comic book<br/>Rare - black and white comic book with a colored main character<br/>Super Rare - fully colored comic book<br/><br/>NFT reflects the box for the comic, you can unpack and check its rarity at www.comics.ly.<br/>The comic is a collectors item and will be 8 pages in length.<br/><br/>The comics will be available on Ebisus Bay after the mint.',
    abi: cd2.abi,
    launchDate: 'July 4, 2022 12:00:00 UTC',
    soldOut: false,
    hidden: false,
    frontpage: true,
    max: 20,
    price: 275,
    discountPrice: 275,
    maxTokens: 1111,
    cid: 'QmbNTxRQrNpeFzNCSWsBMiWuo3MhpBYPp7i55TrWCygpBq'
  },
  connections: {
    title: 'Connections',
    website: 'https://www.susandoyleart.com/nftcollection',
    twitter: 'https://twitter.com/susandoyleart',
    address: '0x5835dD398f432C7c41601c03198479Ea63806bA0',
    testnetAddress: '0x5835dD398f432C7c41601c03198479Ea63806bA0',
    thumbnail: 'drop.jpg',
    image: 'connections.gif',
    shortDescription: 'Dive into a vibrant world of positive vibes - a collection of 99 NFTs',
    description: '<b>Connections</b> by Susan Doyle<br/><br/>Dive into a vibrant world of positive vibes and transcend yourself past the physical form and get lost in a world of intense colours and free flowing energy. This limited collection is all about the connections we make with our own selves and all that is around us, both in nature and with the human spirits that we connect with on a daily basis. The energies that we come in contact with make impressions on our psyche, so connecting with positive energies will not only uplift us, but will ignite the fire in our soul. ',
    abi: connections.abi,
    launchDate: 'June 11, 2022 18:30:00 UTC',
    frontpage: true,
    soldOut: false,
    hidden: false,
    max: 20,
    price: 299,
    discountPrice: 299,
    maxTokens: 120,
    cid: 'QmZ1tR5HPx7PmH8kJ3NZCyHKwpzkm6wspdKr4CtWWprcgk'
  },
  crougarx: {
    title: 'CrougarX',
    discord: 'https://discord.gg/4R3wrrpSj2',
    address: '0x45958Cb5ede8dc9E1a797C1526D4523db1471F15',
    testnetAddress: '0x45958Cb5ede8dc9E1a797C1526D4523db1471F15',
    thumbnail: 'drop.gif',
    image: 'crougarx.gif',
    shortDescription: 'CrougarX is a limited collection of 1,111 NFTs derived from the 1st Gen.',
    description: 'CrougarX is the 2nd Generation of the Crougar NFT project. It is intended to have a higher scarcity, as well as more unique utilities and benefits to holders than Gen 1.<br/><br/>CrougarX is a very limited collection of 1,111 NFTs derived from the first Crougar collection, with several exciting aesthetic differences, such as a completely redesigned set of traits in a pixelated style, a variety of completely new traits, as well as the introduction of animated components, such as backgrounds.',
    abi: crougarx.abi,
    launchDate: 'May 27, 2022 19:00:00 UTC',
    soldOut: true,
    frontpage: true,
    hidden: false,
    max: 20,
    price: 199,
    discountPrice: 199,
    maxTokens: 1111,
    cid: 'QmVkrwFxdR1xxCttzjdoW69qPizmiz1aXJh3VPjqVvx5pk'
  },
  chibis: {
    title: 'Cronos Chibis NFT',
    website: 'https://cronoschibis.com/',
    twitter: 'https://twitter.com/cronoschibis',
    discord: 'https://discord.gg/KAevBYXTJJ',
    address: '0x84Fda8563E6556a7Fb1578E10e114dB56d64638d',
    testnetAddress: '0x84Fda8563E6556a7Fb1578E10e114dB56d64638d',
    thumbnail: 'drop.png',
    image: 'chibis.gif',
    shortDescription: 'Cronos Chibis is a drop of 2,222 detailed manga characters deployed on the Cronos chain.',
    description: 'On 14th May, 2222 Cronos Chibis will be warped into the Cronos universe! As they battle their way through different worlds, only the strongest and sharpest Chibis will reach their Ultimate Chibi form (limited to 50 total). Who knows what treasures may await throughout their adventures.<br/><br/>The community will also help design the first Cronos Chibis manga series as their NFTs can be chosen to star in a real manga storyline. Rewards will be flowing throughout our quests and games nights. Passive income via staking is just one of the numerous utilities Chibis will bring. Come join the Cronos Chibis and become family.',
    abi: chibis.abi,
    launchDate: 'May 14, 2022 17:00:00 UTC',
    soldOut: false,
    frontpage: true,
    max: 20,
    price: 250,
    discountPrice: 200,
    maxTokens: 2222,
    cid: 'QmaankFtqYMWMkCqPYnp8dcvN7aDzhzwhNSweugeYgi9iz'
  },
  cromad: {
    title: 'CROmads',
    twitter: 'https://twitter.com/CROmadsNFT',
    discord: 'https://discord.gg/86B5ZWth',
    address: '0xe7C032f7aC5CCb8C5eadD0c59c6aE8eC8E1868dc',
    testnetAddress: '0xe7C032f7aC5CCb8C5eadD0c59c6aE8eC8E1868dc',
    thumbnail: 'drop.png',
    image: 'cromad.gif',
    shortDescription: 'CROmads are a limited collection of 1234 NFTs travelling Cronos.',
    description: 'CROmads are a limited collection of 1234 nomads that found themselves exploring the Cronos Chain! We are a community driven collectibles project with 100% of royalties going back to the community! Across the 1234 CROmads in our collection, no two are the same! Join us as we travel Cronos together and embark on an epic journey!',
    abi: cromad.abi,
    launchDate: 'May 30, 2022 18:00:00 UTC',
    soldOut: true,
    frontpage: true,
    hidden: false,
    max: 20,
    price: 99,
    discountPrice: 75,
    maxTokens: 1234,
    cid: 'QmY7M4LZNLX3FMmsdCubMNdcCeFfUyZgbjpzLK7tMQQd6C'
  },
  crodeo2: {
    title: 'CrodeoApes Reinforcements',
    website: 'https://crodeoapes.com/',
    twitter: 'https://twitter.com/CrodeoApes/',
    discord: 'https://discord.io/crodeo',
    address: '0x7e656e5bF65029Fc36E4e3bD733FB7BEa5F4403b',
    testnetAddress: '0x7e656e5bF65029Fc36E4e3bD733FB7BEa5F4403b',
    thumbnail: 'drop.png',
    image: 'crodeo2.gif',
    shortDescription: 'CrodeoApes is the first ever trading based NFT project on Cronos.',
    description: 'CrodeoApes is the first ever trading based NFT project on Cronos. After the successful First Alliance drop, it is now time for the Reinforcements Alliance to mint.<br/><br/>Trading utilities:<br/>- Access to trading channels where analysts show their ideas and trading possibilities.<br/><br/>- Community trading wallet, 10% of all sales will go into the community trading wallet. With the help of analysts we will try to grow the funds in this wallet and add the profits into staking pools.<br/><br/>-We are gonna develop an informative trading platform in the future where holders of the CrodeoApes Reinforcements Alliance get a discount on our subscription packages.Holders will get a profile picture version of the NFTs they hold. We will announce the snapshot date later.<br/><br/>As you may have noticed, we are very passionate about crypto trading and we are giving a lot of utilities regarding this topic. We want the people who are holding CrodeoApes to benefit from this, because trading out there can be wild!',
    abi: crodeo2.abi,
    launchDate: 'May 8, 2022 17:30:00 UTC',
    soldOut: false,
    frontpage: false,
    hidden: true,
    max: 25,
    price: 250,
    discountPrice: 200,
    maxTokens: 1050,
    cid: 'QmYnj7T4owiCDqNbgZnYgKj9f8LxCaBSZRC2exYceUGcoA'
  },
  bandits: {
    title: 'Bandits',
    twitter: 'https://twitter.com/BANDITS750',
    discord: 'https://discord.gg/2s7YMT6zK9',
    address: '0xf71C55Fceb76E9d3fa1329719346918f9c4D17f0',
    testnetAddress: '0xf71C55Fceb76E9d3fa1329719346918f9c4D17f0',
    thumbnail: 'drop.gif',
    image: 'bandits.gif',
    shortDescription: 'Holding a BANDIT Is Key to save your spot in line to the vault door that 20 safes of exceptional value to 20 BANDITS with the correct key',
    description: 'Inside knowledge was leaked about a vault room tucked securely in Bank Crono holding 20k Cro in 20 safes.<br/><br/>Word of this spread to Bandits far and wide. With haste 777 bandits rose to occasion of the heist of a life time. Each bandit possessing skills to help them get their hands on a key.<br/><br/>Day in and day out they scoured until each key was found. Those witty bandits now stand facing the vault, tightly gripping their key, hoping theirs will be the one to open one of the 20 safes.<br/><br/>Which 20 Bandits will hold those correct keys? Whatever treasures of that vault will be theirs to claim.<br/><br/>Little do those rascal bandits know one vault door alone holds 10k Cro! The safes are waiting to be opened. Being a Bandit is KEY!',
    abi: bandits.abi,
    launchDate: 'May 8, 2022 18:00:00 UTC',
    soldOut: true,
    frontpage: false,
    hidden: true,
    max: 20,
    price: 250,
    discountPrice: 225,
    maxTokens: 777,
    cid: 'QmVZLTJ3DTTjZqtK2FRcD4fsepPeLPMGerkgiiFGAtSJa3'
  },
  bullishbeavers: {
    title: 'Bullish Beavers',
    twitter: 'https://twitter.com/BullishBeavers',
    discord: 'https://discord.gg/Yc6rCjbBd5',
    address: '0xD238FF8919B659d301d751259bFe2d635992aC53',
    testnetAddress: '0xD238FF8919B659d301d751259bFe2d635992aC53',
    thumbnail: 'drop.png',
    image: 'bullishbeavers.gif',
    shortDescription: '1500 of the most Bullish Beavers you have ever seen. Come join the dam-fam!',
    description: 'A limited high-quality PFP collection consisting of only 1500 Bullish Beavers will be making their way to the Cronos chain!<br/><br/>Aside from owning a great piece of art, you will be granted the ability to earn passive income rewards as well as participate in our DAO which allow holders to voice their opinion on certain proposals with our project. We will be funding our staking directly from royalties that will be set on all secondary market sales as we will be giving 100% of royalties back to the communities staking pool and we also look forward to honoring our charity donation which our community will pick post-mint!<br/><br/>We will be doing giveaways with other projects that our community can participate in. Giveaways which include; whitelist spots, CRO giveaways, and NFTs. We will also be giving a total of 20,000CRO, 5000CRO to 4 separate Bullish Beavers holders on completion of mint! (1 NFT= 1 Entry) All holders will be entitled to exclusive giveaways, airdrops, and contests and we will be airdropping random minters during our minting phase as a way to redistribute mint revenue back to our community right away as we aim to provide a strong community that will take our dam-fam to the next level!',
    abi: bullishbeavers.abi,
    launchDate: 'April 17, 2022 14:30:00 UTC',
    soldOut: true,
    frontpage: false,
    max: 20,
    price: 225,
    discountPrice: 175,
    maxTokens: 1500,
    cid: 'QmfQ5VNFkNDGxpJDpFLuJe2du41HnvKr2PbUDzhmZ5ZJ9g'
  },
  cropets: {
    title: 'CroPets',
    website: 'https://cropetnft.com/',
    discord: 'https://discord.gg/eaxMAhBafB',
    twitter: 'https://twitter.com/CroPetNFT',
    address: '0x8513af602e45bC60D94f0033edc6E983be4B4b3f',
    testnetAddress: '0x8513af602e45bC60D94f0033edc6E983be4B4b3f',
    thumbnail: 'cropets.jpg',
    image: 'cropets.gif',
    shortDescription: 'A collection of 400 CroPet NFTs that support animal welfare.',
    description: 'A collection of 400 CroPet NFTs on Cronos. Packed with utilities!<br/><br/>Collection designed to increase love for animals and help animals in animal shelters. 50% of profits will go to animal shelters and everyone in our Discord will be able to see this live! 100% of royalties will go back to community, that will be earned by staking.<br/><br/>CroPet NFT owners will get special role on Discord. This role unlocks new channels, that will access to exclusive giveaways, CroPet SHOP and special voting system where owners will be able to vote what they want for generation 2. Owners who will mint 10 or more NFTs will get special role on Discord that is limited only for generation 1. This role owners will be able to choose 1 attribute without voting and even get real life surprise from CroPet team.',
    abi: cropets.abi,
    launchDate: 'March 18, 2022 18:00:00 UTC',
    soldOut: true,
    frontpage: false,
    max: 20,
    price: 250,
    discountPrice: 200,
    maxTokens: 400,
    cid: 'QmTbTcLYes2ME4KKZxPy3ugAfi8QSJ3hCEC7Myr82oFTGg'
  },
  islandthunder: {
    title: 'Island Thunder',
    website: 'https://islandthunder.io',
    twitter: 'https://twitter.com/island_thunder',
    address: '0x20aAf55ce6784E2B43A6A143f664Cd9602B04848',
    testnetAddress: '0x20aAf55ce6784E2B43A6A143f664Cd9602B04848',
    thumbnail: 'drop.gif',
    image: 'islandthunder.gif',
    shortDescription: 'The $THUNDER Token is an NFT Token created for the #CroFam and #JungleFam communities',
    description: 'As seen originally dropping on Ethereum, and selling out on OpenSea, the $THUNDER Token now arrives on the cronos chain where it all began! Born in The Jungle, and made for the #CroFam, the $THUNDER Token will forever be the link that minted us all together, and your exclusive membership to the #JungleFam 🙌🏽✊🏽♾🌴⚡️',
    abi: islandthunder.abi,
    launchDate: 'April 23, 2022 23:20:00 UTC',
    soldOut: true,
    hidden: false,
    frontpage: true,
    max: 5,
    price: 420,
    discountPrice: 375,
    maxTokens: 420,
    cid: 'QmdzAJ3yhdKgfSZ71rrqWqZCR4FJ5YxYVa6vDVJyD6vkSM'
  },
  zoonft: {
    title: 'Zoo NFT',
    address: '0xFe5CB04443D953B8888bAea08A407C38522c3b21',
    testnetAddress: '0xFe5CB04443D953B8888bAea08A407C38522c3b21',
    thumbnail: 'drop.jpg',
    image: 'zoonft.gif',
    shortDescription: 'A charity based, 2,000 count NFT project with amazing utility',
    description: 'This is the first drop by Zoo NFT. 2000 Early Edition pets. They have escaped the zoo they are running wild! Catch as many as you can! Staking, charity, and games & giveaways! This project is to fund and build a geo-catching mobile NFT game.',
    abi: zoonft.abi,
    launchDate: 'April 15, 2022 15:00:00 UTC',
    soldOut: false,
    frontpage: false,
    max: 10,
    price: 200,
    discountPrice: 150,
    maxTokens: 2000,
    cid: 'QmVbbJWDFTBTzSsDvLz3UH2suLJ8tdecWTykY21ELeEEvL'
  },
  cbzraffle: {
    title: 'CronoBallz Raffle',
    twitter: 'https://twitter.com/crono_ballz',
    website: 'https://www.cronoballz.com/',
    discord: 'https://discord.com/invite/Xrfj7bYpRP',
    address: '0x912F5c799A679344F0C9984BFdb870a9e871c378',
    testnetAddress: '0x912F5c799A679344F0C9984BFdb870a9e871c378',
    thumbnail: 'drop.png',
    image: 'cbzraffle.gif',
    shortDescription: 'CronoBallz Ball Park Raffle.',
    description: 'CronoBallz Founders and Team recently made a big new feature on News 4 Jacksonville, FL in which we helped repair some much needed bleachers so parents could be able to comfortably attend their kids sporting events! This raffle goes toward the continuous support toward the BALLPARK.<br>Our mission to date is focused on helping youths get back into sport. Thanks to your help minting CronoBallz NFTs, we have proudly assisted over 15 individuals ranging from registration fees, uniforms, cleats, balls, gloves, helmets and more... Recently interviewed on news 4 Jacksonville, Founder Omar and his team over at CronoBallz showed the power of NFTS and how it can make positive impact on Real Life events.',
    abi: cbzraffle.abi,
    launchDate: 'September 15, 2022 20:00:00 UTC',
    frontpage: true,
    soldOut: true,
    hidden: true,
    max: 20,
    price: 99,
    discountPrice: 99,
    maxTokens: 65,
    cid: 'QmY5UjALYincJSgcQ1CyoQEZBxJbYjGsngjM1zK59Z6fui'
  },
  armyburns: {
    title: 'CroArmy Burns',
    address: '0x68500E24a226B75eE603F4040a647DB1fAa8f0fE',
    testnetAddress: '0x68500E24a226B75eE603F4040a647DB1fAa8f0fE',
    thumbnail: 'drop.gif',
    image: 'cropunks.gif',
    shortDescription: 'CroArmy Burns, fueled by $ARMY, are Lighting up Cronos',
    description: 'The CroArmy Burn NFT proceeds will be solely used to buyback and burn the CroArmy $ARMY token, which is tradeable on CRODEX. For every BURN minted, the hodler will receive 1/1000th of our treasury burn allocation forever. ',
    abi: armyburns.abi,
    launchDate: 'April 10, 2022 16:30:00 UTC',
    soldOut: true,
    frontpage: false,
    hidden: true,
    max: 20,
    price: 25,
    discountPrice: 25,
    maxTokens: 4000,
    cid: 'QmSZSWHLDP6snfDNfDifqW6uuAXXroJWP7qYh8xWGXasSm'
  },
  babycroge: {
    title: 'Baby Croge',
    address: '0x1d954Bd544f203bdDC52Fd8f03Eb7Ea4aDbDF3e2',
    testnetAddress: '0x1d954Bd544f203bdDC52Fd8f03Eb7Ea4aDbDF3e2',
    thumbnail: 'drop.png',
    image: 'babycroge.gif',
    shortDescription: 'Baby Croges are an army of 400 adorable Croges',
    description: 'Baby Croge is the best "Baby" token on Cronos. Following in the footsteps of his father Croge, Baby has achieved massive success and has built an incredible community in a very short time.<br/><br/>The Baby Croge NFTs are not only adorable and versatile, but holding them makes you a key member of the Baby Croge community. And even more intriguingly, You will be able to stake your Baby Croge NFTs and earn rewards in Croge. Passive income has never been this easy.<br/><br/>The team behind Baby Croge are dedicated, thorough and always there for their community. They have plans to make Baby Croge THE Baby token of Cronos, the way Baby Doge did before them.<br/><br/>So get your cute Baby Croges and join the most incredible community you will ever find!',
    abi: babycroge.abi,
    launchDate: 'March 31, 2022 00:30:00 UTC',
    soldOut: true,
    frontpage: true,
    hidden: true,
    max: 10,
    price: 100,
    discountPrice: 100,
    maxTokens: 400,
    cid: 'QmXUNUfoRXgSZFtpQmvL4YzBkBMRfaqqCRBhrHiYxFpbck'
  },
  scarecro: {
    title: 'ScareCRO',
    address: '0x5f399c9029F397E3F6b2e3bE89478884Fd201e57',
    testnetAddress: '0x5f399c9029F397E3F6b2e3bE89478884Fd201e57',
    thumbnail: 'scarecro.png',
    image: 'scarecro.gif',
    shortDescription: 'ScareCROs is a limited collection of 999 spooky NFTs with unique traits and utility.',
    description: 'ScareCROs is a limited collection of 999 spooky NFTs with unique traits and utility. Lets protect our crop together!<br/><br/>This is the first generation of ScareCROs and the collection is filled with unique traits and incredible artwork. ScareCROs was created by a team who is passionate about digital art and is driven by a strong community.<br/><br/>The ScareCROs utility provides staking opportunities through a collaboration with Crodex along with a strong, eager community. The ScareCRO DAO will be somewhere holders can cast their vote on future generations.',
    abi: scarecro.abi,
    launchDate: 'March 10, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 249,
    discountPrice: 199,
    maxTokens: 999,
    cid: 'QmZsvPNPs16ScF1rx9sCZE7AaqhVSL5oNwTqFU4FSS3oid'
  },
  cronogoddesses: {
    title: 'CronoGoddesses',
    address: '0x972A2DDd1E364105cDe1f6E70f8078974A3D7A9b',
    testnetAddress: '0x972A2DDd1E364105cDe1f6E70f8078974A3D7A9b',
    thumbnail: 'cronogoddesses.jpg',
    image: 'cronogoddesses.gif',
    shortDescription: 'Inspired By Women For Women. Cronogoddess: a 1000 NFT collection.',
    description: '<b>Breeding:</b> Hold 1 CronoGod and 1 CronoGoddess get airdropped a CronoGodKid (kids will only be airdropped no mint will be available)<br/><br/><b>Community Aid Wallet:</b> 25% Goes into a community wallet to help women in need. Our Cronogoddessess Illuminaudee and Aimy Crobeil are excellent host in women Twitter AMA, and always willing to offer assistance how they can. So we created this wallet, it will be there for women that need assistance in real life situations. Our goddess will protect you.<br/><br/><b>Charity:</b> 10% goes toward charities chosen by our holders.<br/><br/><b>Seasonal Staking:</b> As a small collection similar to CronoGods will will be staking Quaterly. This helps build fund from royalties to sustain a very healthy pool while we empower of goddess on crypto, nft, staking, and more.<br/><br/><b>(Connect your wallet to verify for WL discount and mint for 125CRO)</b>',
    abi: cronogoddesses.abi,
    launchDate: 'March 14, 2022 22:00:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 175,
    discountPrice: 125,
    maxTokens: 1000,
    cid: 'QmRb54EoqBeo6jHygBs7t3w8kpkJ8GogfAu9CnS3G6R8ue'
  },
  cronosbotsociety: {
    title: 'Cronos Bot Society',
    address: '0x57bB37249fAaC77c404a3CddE08687A20aA7a77D',
    testnetAddress: '0x57bB37249fAaC77c404a3CddE08687A20aA7a77D',
    thumbnail: 'cronosbotsociety.png',
    image: 'cronosbotsociety.gif',
    shortDescription: 'Welcome to Cronos Bot Society… The first Bot Society to hit the Cronos Network.',
    description: 'Cronos Bot Society is a collection of 2,000 NFTs with striking artwork, a magnitude of traits and utility to go with it!<br/><br/>Are you prepared to follow the BOTKING in search of rare CPU Upgrade Chips which are hidden within the Cronos Code… Each CPU Upgrade Chip will give the holder the chance to upgrade their bot into a BILLIONAIRE BOT, which will be a 1 of 1 NFT with extreme rarity due to the small supply (Total supply 100).<br/><br/>100% of royalties will be put into the CRODEX staking platform each time it reset, this gives every holder the ability to gain passive income. By owning a Cronos Bot Society NFT, you will be part of the society and help dictate how the project develops whether that be a Gen 2 or a different direction.',
    abi: cronosbotsociety.abi,
    launchDate: 'March 19, 2022 23:00:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 200,
    discountPrice: 150,
    maxTokens: 2000,
    cid: 'QmPA6MSDaoCve9wnKqoVeYAN73kSG9btK9sqDGFdK3N56N'
  },
  crodoges: {
    title: 'CroDoges',
    website: 'https://crodoges.com/',
    twitter: 'https://twitter.com/CroDogesClub',
    discord: 'https://discord.gg/GBxAQFbJWQ',
    address: '0xe7f4b4c26912dbB5764353eBD8D6aCD56A5B7435',
    testnetAddress: '0xe7f4b4c26912dbB5764353eBD8D6aCD56A5B7435',
    thumbnail: 'drop.png',
    image: 'crodoges.gif',
    shortDescription: 'CroDoges are a unique and limited collection of 1800 NFT',
    description: 'CroDoges are a unique and limited collection of 1800 NFT.<br/><br/>Metaverse : By purchasing a CroDoge you automatically become the owner of a 1x1 plot in the Metaverse under construction. Each plot owner receives a portion of the profits generated from renting out larger plots, providing a steady passive income. When you buy 4 or 9 CroDoges, you get plots that are next to each other, such as 2x2 or 3x3. Advertise yourself or others on your plots and rent them out and earn money in the future.<br/><br/>Staking : Stake your CroDoges as early as 24 hours after the start of the mint at very favorable conditions.<br/><br/>Airdrop : Over 150 prizes will be drawn for CroDoges owners during the mint (including CroDoges with special attributes). There will be many contests and benefits after the mint ends.<br/><br/>Extras : Receive free NFT or WL for our and our business partners future projects. Decide on the direction of the Metaverse through a special voting system.',
    abi: crodoges.abi,
    launchDate: 'March 24, 2022 18:30:00 UTC',
    soldOut: true,
    frontpage: true,
    hidden: true,
    max: 9,
    price: 385,
    discountPrice: 385,
    maxTokens: 1800,
    cid: 'QmbsU2oBbF5wX9vG3rRf1NyHRhsDZ1Vp4kGk2zxK4ry7Jd'
  },
  croarmy: {
    title: 'CroArmy',
    address: '0x16a8590308b4E4C5bd8716A4F2DF40F44bd19641',
    testnetAddress: '0x16a8590308b4E4C5bd8716A4F2DF40F44bd19641',
    thumbnail: 'drop.png',
    image: 'croarmy.gif',
    shortDescription: 'Join the CroArmy, only 200 mints available!',
    description: 'The CroArmy $ARMY is launching the first NFT of its kind. ONLY 200 CroArmy Panthers are available to mint.<br/><br/>The funds from this sale will be pooled in our community wallet (treasury) after mint completion. Treasury funds will be invested as the community sees fit into several projects. This is already a strong community but it will become even stronger as we are all gaining profits together. Weekly profits will be distributed to  NFT holders, then used to burn the $ARMY supply, and reinvested weekly.<br/><br/>The Founder of this project is an Iraq War Veteran and is donating portions of all profits to charity. The charity donations will be at the communities discretion, as they can even be a local (verifiable) charity.<br/><br/>Merch drop. Shipped. Delivered. Within 30 days (depending on local supplier).',
    abi: croarmy.abi,
    launchDate: 'April 1, 2022 10:00:00 UTC',
    soldOut: true,
    frontpage: true,
    hidden: true,
    max: 1,
    price: 600,
    discountPrice: 500,
    maxTokens: 500,
    cid: 'QmS7gZDo4fdvWwNoiNrKu1UCziKsk6eQQeHiEP5rYYKNXs'
  },
  croflips: {
    title: 'CroFlips',
    address: '0x24116CF1B25C3A284d39F5E357865E238143084C',
    testnetAddress: '0x3c8FBD890DbA72275839C3AC17026ABd192C50ce',
    thumbnail: 'croflips.png',
    image: 'croflips.gif',
    shortDescription: '444 NFTs, royalty sharing, revenue sharing, leaderboards and more.',
    description: 'Double or nothing! 50/50 with a 3% fee that goes to <a style="color: #000; font-weight: bold" target="_blank" href="https://croflips.com">CroFlips</a> NFT holders. Join the Flipper Family and earn on house profits, participate in giveaway contests and more!<br/><br/> CroFlips holders will also be entitled to early access to <b>Rooster Rumble</b>,  - a new animated game for Cronos that simulates roosters duking it out to the bitter end! <br/> <br> <div class="alert alert-info">Due to the wrong LOOT contract being passed whitelist is not automatically applied. Please Message CasperGhost#8779 on Discord or <a style="color: blue" href="https://twitter.com/croflips">@croflips</a> on DM if you hold LOOT to get Whitelist.</div>',
    abi: croflips.abi,
    launchDate: 'February 24, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 350,
    discountPrice: 300,
    maxTokens: 444,
    cid: 'QmZEdq735yGkiWfST6D7KH3cHw6EPdwyy1NXSbKiMbKz2x'
  },
  blackcat: {
    title: 'Blackcat',
    address: '0x49D85f22b6c65593018355D7B090164A06F8090b',
    testnetAddress: '0x49D85f22b6c65593018355D7B090164A06F8090b',
    thumbnail: 'croflips.png',
    image: 'croflips.gif',
    shortDescription: '444 NFTs, royalty sharing, revenue sharing, leaderboards and more.',
    description: 'Double or nothing! 50/50 with a 3% fee that goes to <a style="color: #000; font-weight: bold" target="_blank" href="https://croflips.com">CroFlips</a> NFT holders. Join the Flipper Family and earn on house profits, participate in giveaway contests and more!<br/><br/> CroFlips holders will also be entitled to early access to <b>Rooster Rumble</b>,  - a new animated game for Cronos that simulates roosters duking it out to the bitter end! <br/> <br> <div class="alert alert-info">Due to the wrong LOOT contract being passed whitelist is not automatically applied. Please Message CasperGhost#8779 on Discord or <a style="color: blue" href="https://twitter.com/croflips">@croflips</a> on DM if you hold LOOT to get Whitelist.</div>',
    abi: blackcat.abi,
    launchDate: 'February 24, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 0,
    discountPrice: 0,
    maxTokens: 312,
    cid: 'QmebxoKCrXD1HuYW7yohUQCwN8SqUTe1ScFcBUbNb4XZuF'
  },
  cpu: {
    title: 'CPU Upgrade Chip',
    address: '0x92E706cfa069d6366305bC659ff16ac792a47b82',
    testnetAddress: '0x92E706cfa069d6366305bC659ff16ac792a47b82',
    thumbnail: 'croflips.png',
    image: 'croflips.gif',
    shortDescription: '444 NFTs, royalty sharing, revenue sharing, leaderboards and more.',
    description: 'Double or nothing! 50/50 with a 3% fee that goes to <a style="color: #000; font-weight: bold" target="_blank" href="https://croflips.com">CroFlips</a> NFT holders. Join the Flipper Family and earn on house profits, participate in giveaway contests and more!<br/><br/> CroFlips holders will also be entitled to early access to <b>Rooster Rumble</b>,  - a new animated game for Cronos that simulates roosters duking it out to the bitter end! <br/> <br> <div class="alert alert-info">Due to the wrong LOOT contract being passed whitelist is not automatically applied. Please Message CasperGhost#8779 on Discord or <a style="color: blue" href="https://twitter.com/croflips">@croflips</a> on DM if you hold LOOT to get Whitelist.</div>',
    abi: cpu.abi,
    launchDate: 'February 24, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 350,
    discountPrice: 300,
    maxTokens: 444,
    cid: 'QmRTuU6rAz6gFvumXTDWX87aAFdDTw7QCdEpC3dvcHasH5'
  },
  viking: {
    title: 'Viking Arc Card',
    address: '0x44C64CA09Fe913382d41551cEaD0DEc9f08585a2',
    testnetAddress: '0x44C64CA09Fe913382d41551cEaD0DEc9f08585a2',
    thumbnail: 'croflips.png',
    image: 'croflips.gif',
    shortDescription: '444 NFTs, royalty sharing, revenue sharing, leaderboards and more.',
    description: 'Double or nothing! 50/50 with a 3% fee that goes to <a style="color: #000; font-weight: bold" target="_blank" href="https://croflips.com">CroFlips</a> NFT holders. Join the Flipper Family and earn on house profits, participate in giveaway contests and more!<br/><br/> CroFlips holders will also be entitled to early access to <b>Rooster Rumble</b>,  - a new animated game for Cronos that simulates roosters duking it out to the bitter end! <br/> <br> <div class="alert alert-info">Due to the wrong LOOT contract being passed whitelist is not automatically applied. Please Message CasperGhost#8779 on Discord or <a style="color: blue" href="https://twitter.com/croflips">@croflips</a> on DM if you hold LOOT to get Whitelist.</div>',
    abi: viking.abi,
    launchDate: 'February 24, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 350,
    discountPrice: 300,
    maxTokens: 175,
    cid: 'QmUhERniLshobtvLpyKCxUax6pk6qLZqrLkfYPLbYNEuzP'
  },
  bbecc: {
    title: 'Busy Bear Elite Club Card',
    address: '0x98F9195CD7842Aef783d1255CFe6AA97F32b2564',
    testnetAddress: '0x98F9195CD7842Aef783d1255CFe6AA97F32b2564',
    thumbnail: 'croflips.png',
    image: 'croflips.gif',
    shortDescription: '444 NFTs, royalty sharing, revenue sharing, leaderboards and more.',
    description: 'Double or nothing! 50/50 with a 3% fee that goes to <a style="color: #000; font-weight: bold" target="_blank" href="https://croflips.com">CroFlips</a> NFT holders. Join the Flipper Family and earn on house profits, participate in giveaway contests and more!<br/><br/> CroFlips holders will also be entitled to early access to <b>Rooster Rumble</b>,  - a new animated game for Cronos that simulates roosters duking it out to the bitter end! <br/> <br> <div class="alert alert-info">Due to the wrong LOOT contract being passed whitelist is not automatically applied. Please Message CasperGhost#8779 on Discord or <a style="color: blue" href="https://twitter.com/croflips">@croflips</a> on DM if you hold LOOT to get Whitelist.</div>',
    abi: bbecc.abi,
    launchDate: 'February 24, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 350,
    discountPrice: 300,
    maxTokens: 175,
    cid: 'Qmf8GQ2SMq4jmZBLGwccb3mbC1afjYnranjScrfEjr24Mh'
  },
  electricvibrations: {
    title: 'Electric Vibrations by Susan Doyle',
    twitter: 'https://twitter.com/susandoyleart',
    website: 'https://www.susandoyleart.com/nft',
    address: '0x6A5A7557DD15c14f5b05cbf5A0DF6eC32Dd65832',
    testnetAddress: '0x6A5A7557DD15c14f5b05cbf5A0DF6eC32Dd65832',
    thumbnail: 'drop.png',
    image: 'electricvibrations.gif',
    shortDescription: 'Electric Vibrations is a 1/1 collection of 26 unique psychedelic abstract pieces',
    description: '<b>Electric Vibrations</b> by Susan Doyle<br/><br/>Invigorate your soul and get lost in a world of colour, energy and positive vibes. As a traditional painter for over 15 years, I have always been fascinated with the dueling forces of dark/light, and chaotic/calm, both in our surroundings and within ourselves, and much of my work has been focused on this theme.<br/><br/><b>Electric Vibrations</b> is an abstract take on those connections, highlighting the vibrancy you feel when you accept these forces as they are, and understand their symbiotic relationship.<br/><br/>The spiritual vibrations that you feel when you find that positive or zen state of mind will not only light up your own world, but all those that you share it with. <br/><br/>Each artwork is 6000x6000px at 300dpi',
    abi: electricvibrations.abi,
    launchDate: 'April 23, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: false,
    frontpage: true,
    hidden: true,
    max: 10,
    price: 299,
    discountPrice: 299,
    maxTokens: 26,
    cid: 'QmV8aHyMMdB7vMds6Mdt63c6mBrPv6BRMs7nmm4p5BWFPi'
  },
  braindeadmonkeys: {
    title: 'Braindead Monkeys',
    address: '0xda663336FEC3Ef6e310be6522F35E391aAE6F43a',
    testnetAddress: '0xda663336FEC3Ef6e310be6522F35E391aAE6F43a',
    thumbnail: 'drop.gif',
    image: 'braindeadmonkeys.gif',
    shortDescription: 'A rare collection of 1111 Braindead Monkeys, with over 200 unique traits & staking.',
    description: 'Braindead Monkeys Gen 1 is a unique and rare collection of only 1111 NFTs with a mint limit of 10 per wallet.<br/><br/>The collection includes 206 unique traits bringing striking high-end art that will become a key feature in building out our brand via colabs, merchandise and more with the aim to continually build value via market awareness to this and our future collections.<br/><br/>Staking at Crodex which will start 14 days after the mint sells out offering holders the opportunity to earn a passive income.<br/><br/>The first 111 wallets that mint a Braindead Monkey will also receive a free airdrop of our Viking Arc Founders Club animated NFT in the week following sell out. This card will guarantee the holder access to our future Braindead Monkey drops as well as involvement with other selected drops via Lootpad with more utilities built into the card as our journey progresses',
    abi: braindeadmonkeys.abi,
    launchDate: 'April 3, 2022 18:30:00 UTC',
    soldOut: true,
    frontpage: true,
    hidden: true,
    max: 10,
    price: 275,
    discountPrice: 240,
    maxTokens: 1111,
    cid: 'QmfPjyZdWPS2beNzfqKnb53HUvumy4UUxop13mVZJWnWnv'
  },
  crodeoapes: {
    title: 'CrodeoApes',
    address: '0xF67877C2AF584083a68aA3014E82e068527Cf9A3',
    testnetAddress: '0xF67877C2AF584083a68aA3014E82e068527Cf9A3',
    thumbnail: 'drop.png',
    image: 'crodeoapes.gif',
    shortDescription: 'CrodeoApes is a two-tiered collection of NFTs with TA utility',
    description: 'YEEEEHAWWW, Crodeo Apes is a wild west themed NFT project on Cronos.<br/><br/>The first 100 NFTs will be released on the 27th of March. Minters of the first 100 NFTs get a custom, personalised Crodeo Ape and get access to other exclusive benefits. The other 1150 NFTs will launch a month later.<br/><br/>Project utilities:<br/>- PFP version of NFT airdropped.<br/>- Access to premium on-chain data and technical analysis from our analyst.<br/>- NFT Staking - 100% of the royalties will go right back to the people who stake their NFT.<br/>- Access to premium functions on our upcoming trading platform (later stage).<br/><br/>Join The Alliance, YEEEEHAWW!',
    launchDate: 'March 27, 2022 20:00:00 UTC',
    soldOut: true,
    frontpage: true,
    hidden: true,
    max: 3,
    price: 250,
    discountPrice: 200,
    maxTokens: 120,
    cid: 'QmcKKfzJ3qML8V4vAJdUWPS5CknDF8APe7ZfYfr8dcsY5V'
  },
  handpunks: {
    title: 'Handpunks',
    address: '0x3274C3F706c5d6fe354Fb7B3De806571AF3cF540',
    testnetAddress: '0x3274C3F706c5d6fe354Fb7B3De806571AF3cF540',
    thumbnail: 'handpunks_thumbnail.gif',
    image: 'handpunks.gif',
    shortDescription: 'Handpunks is a OG collection of 111 Unique pixel based hand drawn art.',
    description: 'Not only is each Handpunk unique, but they also give their holders early access to games and it can be used as in-game cards or collectibles.<br/><br/>Handpunk OG holders also receive Airdrop and Royalties from Gen 2. All OG holders will get an opportunity to take part in beta testing of our game: Handpunks Dojo. It is a play-to-earn game (P2E) where OG holders have bigger chances of winning the card game.',
    abi: handpunks.abi,
    launchDate: 'March 11, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 260,
    discountPrice: 240,
    maxTokens: 111,
    cid: 'QmbScTL6h2Uhwdth6yTLQTko3M5P6V1egzb9c3av6d51N3'
  },
  looters: {
    title: 'Looters',
    address: '0xeE0F2Aa0A15d8A7Ddc8f0eE59bC555e43555C98d',
    testnetAddress: '0x79dE8c206f83DF5058E7133Ea3211F005Dd7ea30',
    thumbnail: 'looters.png',
    image: 'looters.gif',
    shortDescription: 'A collection of Loot NFTs that share Lootpad revenues with holders.',
    description: 'Looters is a limited collection of 1,111 NFTs and is the official NFT of the Loot Network community.<br/><br/>By holding Looter NFTs, you’re entitled to passive income that is unlocked by NFT Staking. The Looters NFT Staking pool will be funded through two streams: Royalties on Secondary Market Sales, and a share of revenues generated on LootPad.io.',
    abi: looters.abi,
    launchDate: 'February 20, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 250,
    discountPrice: 200,
    maxTokens: 1111,
    cid: 'QmY5pXVwsAFTNPbj4J1ktm5TbVGopz7VnXBXfcuq57q194'
  },
  CronosFoxElite: {
    title: 'Cronos Fox Elite',
    address: '0xaC22b85bd9B71C7C6FE874C8F710A3AE45f76C76',
    testnetAddress: '0x758566A42C3740e9bbcce7e158130D5BD26A339A',
    thumbnail: 'CronosFoxElite.png',
    image: 'CronosFoxElite.gif',
    shortDescription: 'Join the Elite Club, your NFT is treated as a VIP Ticket for future Projects.',
    description: 'Join the Elite Club, your NFT is treated as a VIP Ticket for future Projects.',
    abi: cronosfoxelite.abi,
    launchDate: 'February 16, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 200,
    discountPrice: 200,
    maxTokens: 250,
    cid: 'QmVRXUYNKDmaiTdFeosmcZBRt8oumCY6v2yxps2S1tNt6M'
  },
  cryptochads: {
    title: 'CryptoChads',
    address: '0x41524cE5E966963CC76D87b39E3E8aac21bCC3FD',
    testnetAddress: '0x41524cE5E966963CC76D87b39E3E8aac21bCC3FD',
    thumbnail: 'cc175b9c0f1b6a8.jpg',
    image: 'cryptochads.gif',
    shortDescription: '',
    description: 'Crypto Chads are a symbol of security, trust and honesty in Crypto! Building a strong, high-spirited community, which promotes legitimacy and transparency is what Crypto Chads aims to do in the DeFi space.<br/><br/>Utilities of Crypto Chads include a multitude of things. The Chad Foundation is the charitable aspect of Chads, which will be its own platform that provides a safe place for everyone to join and win prizes from giveaways and fun light-hearted competitions. This is all an attempt to combat the fraudulent projects and users of DeFi, by giving back to the comunity.<br/><br/>The ChadPad Launchpad is another utility that is starting development. The launchpad will have all your favorite DeFi features such as IDOs, Decentralized Exchange, NFT Marketplace and much more. The ChadPad will focus on creating a Dapp that users feel secure and safe using at all times. The native tokens of the Launchpad will be the CroCHAD token on Cronos as well as the Crypto Chads NFTs. Holding either or both of these will give you access to premium features on the Dapp while also granting allocation on IDOs and presales.<br/><br/>Holding the Generation 1 Crypto Chads NFTs also open the doors for you to access The Chad NFT Club, where you may only enter if you posses one or more of the NFTs. In addition, 10% of all revenue made from EVERY future Crypto Chads Collection will be airdropped to holders of Generation 1 NFTs.<br/><br/>So join the very exclusive group of Generation 1 Crypto Chads NFT holders. There is much more to come from Crypto Chads and the project is only getting started. Join the community on Telegram, Discord and Twitter to stay updated with all the latest news and developments.<br/><br/>Take care of yourselves, take care of eachother and take the time today to tell someone you love them.',
    abi: chad.abi,
    launchDate: 'February 18, 2022 17:00:00 UTC',
    soldOut: false,
    hidden: true,
    max: 5,
    price: 50,
    discountPrice: 50,
    maxTokens: 120,
    cid: 'Qmc3HEnraLbaWc8vb4Mvo1DZeHQooqMvZCKZi2dTigDNhU'
  },
  diamond: {
    title: 'Diamond',
    address: '0xd301F02e44b1eEa1ff925B90755E16d6CCF7C6Dd',
    testnetAddress: '0xd301F02e44b1eEa1ff925B90755E16d6CCF7C6Dd',
    thumbnail: 'cc175b9c0f1b6a8.jpg',
    image: 'cryptochads.gif',
    shortDescription: '',
    description: 'Crypto Chads are a symbol of security, trust and honesty in Crypto! Building a strong, high-spirited community, which promotes legitimacy and transparency is what Crypto Chads aims to do in the DeFi space.<br/><br/>Utilities of Crypto Chads include a multitude of things. The Chad Foundation is the charitable aspect of Chads, which will be its own platform that provides a safe place for everyone to join and win prizes from giveaways and fun light-hearted competitions. This is all an attempt to combat the fraudulent projects and users of DeFi, by giving back to the comunity.<br/><br/>The ChadPad Launchpad is another utility that is starting development. The launchpad will have all your favorite DeFi features such as IDOs, Decentralized Exchange, NFT Marketplace and much more. The ChadPad will focus on creating a Dapp that users feel secure and safe using at all times. The native tokens of the Launchpad will be the CroCHAD token on Cronos as well as the Crypto Chads NFTs. Holding either or both of these will give you access to premium features on the Dapp while also granting allocation on IDOs and presales.<br/><br/>Holding the Generation 1 Crypto Chads NFTs also open the doors for you to access The Chad NFT Club, where you may only enter if you posses one or more of the NFTs. In addition, 10% of all revenue made from EVERY future Crypto Chads Collection will be airdropped to holders of Generation 1 NFTs.<br/><br/>So join the very exclusive group of Generation 1 Crypto Chads NFT holders. There is much more to come from Crypto Chads and the project is only getting started. Join the community on Telegram, Discord and Twitter to stay updated with all the latest news and developments.<br/><br/>Take care of yourselves, take care of eachother and take the time today to tell someone you love them.',
    abi: diamond.abi,
    launchDate: 'February 18, 2022 17:00:00 UTC',
    soldOut: false,
    hidden: true,
    max: 5,
    price: 50,
    discountPrice: 50,
    maxTokens: 100,
    cid: 'QmRK4c6YWBsPLb8MrjRQddUEzb4QRLGVrNHTyvUuAP9Cy4'
  },
  coalition: {
    title: 'Coalition',
    address: '0x85aC0eAc55A62840121c5Bc75368987a52267086',
    testnetAddress: '0x85aC0eAc55A62840121c5Bc75368987a52267086',
    thumbnail: 'cc175b9c0f1b6a8.jpg',
    image: 'cryptochads.gif',
    shortDescription: '',
    description: 'Crypto Chads are a symbol of security, trust and honesty in Crypto! Building a strong, high-spirited community, which promotes legitimacy and transparency is what Crypto Chads aims to do in the DeFi space.<br/><br/>Utilities of Crypto Chads include a multitude of things. The Chad Foundation is the charitable aspect of Chads, which will be its own platform that provides a safe place for everyone to join and win prizes from giveaways and fun light-hearted competitions. This is all an attempt to combat the fraudulent projects and users of DeFi, by giving back to the comunity.<br/><br/>The ChadPad Launchpad is another utility that is starting development. The launchpad will have all your favorite DeFi features such as IDOs, Decentralized Exchange, NFT Marketplace and much more. The ChadPad will focus on creating a Dapp that users feel secure and safe using at all times. The native tokens of the Launchpad will be the CroCHAD token on Cronos as well as the Crypto Chads NFTs. Holding either or both of these will give you access to premium features on the Dapp while also granting allocation on IDOs and presales.<br/><br/>Holding the Generation 1 Crypto Chads NFTs also open the doors for you to access The Chad NFT Club, where you may only enter if you posses one or more of the NFTs. In addition, 10% of all revenue made from EVERY future Crypto Chads Collection will be airdropped to holders of Generation 1 NFTs.<br/><br/>So join the very exclusive group of Generation 1 Crypto Chads NFT holders. There is much more to come from Crypto Chads and the project is only getting started. Join the community on Telegram, Discord and Twitter to stay updated with all the latest news and developments.<br/><br/>Take care of yourselves, take care of eachother and take the time today to tell someone you love them.',
    abi: coalition.abi,
    launchDate: 'February 18, 2022 17:00:00 UTC',
    soldOut: false,
    hidden: true,
    max: 5,
    price: 50,
    discountPrice: 50,
    maxTokens: 100,
    cid: 'QmfHbqSzQxM6P1oF7tbA1559xLKuiC4D4CCJnYwGGXkpzR'
  },
  anw: {
    title: 'ANW Founder Edition Weapons',
    discord: 'https://discord.gg/rztmMaUuZd',
    twitter: 'https://twitter.com/ApesAndWhales_',
    website: 'https://apesnwhales.com',
    address: '0xD34135029855b20ce3a795aFcDf81C907A2f0C90',
    testnetAddress: '0xD34135029855b20ce3a795aFcDf81C907A2f0C90',
    thumbnail: 'drop.png',
    image: 'anw.gif',
    shortDescription: 'Founder Editon Weapons Collection for the Apes n Whales game platform.',
    description: 'Apes n Whales is a project on the Cronos blockchain which includes a token, NFT collections and a Dapp. The project is based around the great battle between marine and land creatures, which will be the theme of the NFT collections. The token serves as both a deflationary self reflecting asset and the native token of the Dapp that will be built for the project’s utilities.<br/><br/>This is the exclusive Founders Edition Weapons Collection. Made to help fund the development and give the early investors a chance to get their hands on some exclusive gear only available in this collection.<br/><br/>Join the battle and get you Founders Edition gear ready for the fight!',
    abi: anw.abi,
    launchDate: 'April 27, 2022 20:00:00 UTC',
    soldOut: false,
    frontpage: true,
    hidden: true,
    max: 50,
    price: 100,
    discountPrice: 75,
    maxTokens: 300,
    cid: 'QmYy3mzG6rv5uCfjnUnPU7r6HdyFFv5jh5WqM2BHNhwMJW'
  },
  toughlions: {
    title: 'Tough Lions',
    discord: 'https://discord.gg/3B2cz9vgp2',
    twitter: 'https://twitter.com/ToughLions1111',
    address: '0xb242399f3f52fcfa8b09d7d6e4C3DBe84D0644fc',
    testnetAddress: '0xb242399f3f52fcfa8b09d7d6e4C3DBe84D0644fc',
    thumbnail: 'drop.png',
    image: 'toughlions.gif',
    shortDescription: 'Tough Lions is a collection of 1,111 unique Tough Lions NFTs',
    description: 'Tough Lions are created by ZAP7 and are limited only 1111 pieces which made up of 7 parts: a mane, eyes, a mouth, an outfit, skin, a weapon and background. Each item had a different rarity. The prominent point of the project is the art work, which was generated to be high-quality and valuable PFP. Our projects first phase roadmap is as follows:<br/><br/>Phase 1: Sale Phase<br/><br/>20% sold — Air drop some CRO and Tough Lions to the holders<br/>40% sold — Create a Tough Lions website and arrange fan art events<br/>60% sold — Tough Lions holders will be able to check the rarity of each lion by Rarity Sniper<br/>80% sold — Start creating Lion’s pet called Hype Hogs for Tough Lion holders<br/>100% sold—Big celebration in the community  and vote to determine the direction of the future collection<br/><br/>Get your Tough Lions avatar in the Mataverse world. Tough Lions is you! Join us!',
    abi: toughlions.abi,
    launchDate: 'April 25, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: false,
    frontpage: true,
    max: 10,
    price: 100,
    discountPrice: 75,
    maxTokens: 1111,
    cid: 'QmdwSaxEHarCM9HJNWuGm3pSjkBK3a1DC1MPQw25exJ7Qj'
  },
  armysoldier: {
    title: 'CroArmy Soldier',
    discord: 'https://discord.gg/QBNUvQTEE8',
    twitter: 'https://twitter.com/CroArmy_eth',
    address: '0xE4a36Aa88d8380DB252558104dbe0D71505aF7a5',
    testnetAddress: '0xE4a36Aa88d8380DB252558104dbe0D71505aF7a5',
    thumbnail: 'drop.jpg',
    image: 'armysoldier.gif',
    shortDescription: 'Introducing the CroArmy Soldier - The beating heart to our $ARMY.  ',
    description: 'The CroArmy Soldier is our second NFT drop for mint. Owners of this Soldier will be eligible for AIRDROPS from the treasury profits. They will also receive discounts on merchandise from our store when it launches in July. Profit sharing, community voting, competition, and excellent research are what is in store for this good looking fella.',
    abi: armysoldier.abi,
    launchDate: 'April 22, 2022 14:00:00 UTC',
    soldOut: false,
    hidden: true,
    frontpage: true,
    max: 20,
    price: 250,
    discountPrice: 200,
    maxTokens: 1000,
    cid: 'QmXvRmPNyL23hXgCKuzwBrVBxNnEsdMSq5uA2QdXsUQ3VR'
  },
  zombabies: {
    title: 'Zombabies',
    website: 'https://www.zombabies.io/',
    discord: 'https://discord.gg/qRr3dCvyKP',
    twitter: 'https://twitter.com/ZombabiesNFT',
    address: '0xD0BD375a43B58Fd8329980898802667a64623F60',
    testnetAddress: '0xD0BD375a43B58Fd8329980898802667a64623F60',
    thumbnail: 'zombabies.png',
    image: 'zombabies.gif',
    shortDescription: '3333 cute corpses crawling on Cronos • Staking • DAO • Merch',
    description: '3333 Zombabies are crawling on Cronos with unique traits as well as utility. The first zombie project on Cronos Blockchain!<br/><br/>We are all about rewarding experiences, digitally and physically. Gain access to our exclusive holders community, and reap the other rewards of owning Zombabies! Our project will reward holders with CRO, NFTs, IRL & virtual  events.  Holding multiple Zombabies will increase rewards. Zombabies DAO will allow holders to vote on the future of the project.<br/><br/>Earn passive income, staking your NFT with Crodex. 100% of royalties will go back to the community! A percentage of our lucky holders receive custom printed Zombabies merch. Holders of 3+ Zombabies get a free t-shirt! A $10,000 CAD donation will be made to a charity of the community’s choice!',
    abi: zombabies.abi,
    launchDate: 'March 20, 2022 18:00:00 UTC',
    soldOut: true,
    frontpage: true,
    max: 20,
    price: 160,
    discountPrice: 120,
    maxTokens: 3333,
    cid: 'QmVrEe4LkANQzTx52zeucurzTNV7mE1sqem7aFaYZrEayE'
  },
sexyseacows: {
    title: 'Sexy Sea Cows',
    discord: 'https://discord.gg/b5whRBECDz',
    address: '0x6A69ac09e86aBca5b5d926a9047C03260C222aa5',
    testnetAddress: '0x6A69ac09e86aBca5b5d926a9047C03260C222aa5',
    thumbnail: 'drop.png',
    image: 'sexyseacows.gif',
    shortDescription: 'An exclusive NFT group cruising through volatile crypto seas together',
    description: 'An exclusive NFT group cruising through volatile crypto seas together<br/><br/>Reasons to get involved:<br/>- 50% of minting revenue will be deposited into MMO vault single stake to earned WCRO.  50% of this farm revenue is airdropped to Sexy Sea Cow holders and the other 50% is swapped into MMO to continue gaining more rewards for the community.<br/>- 1:1 Airdrop to all upcoming projects launched by team<br/>- 100% of secondary sales goes towards NFT Staking, what coin is decided on by community vote!<br/>- Special WL offers<br/>- P2E game in the works which will offer royalties to holders<br/>- Weekly Raffles, bringing everyone together one night in the week to get to know each other and participate in some giveaways.  These weekly raffles can include but are not limited to Cronos prizes to be airdropped, NFT prizes, WL prizes, merch.....',
    abi: sexyseacows.abi,
    launchDate: 'April 24, 2022 12:00:00 UTC',
    soldOut: false,
    hidden: true,
    frontpage: true,
    max: 10,
    price: 250,
    discountPrice: 200,
    maxTokens: 500,
    cid: 'QmeCLwwJd7sKG3m1NsY7HGFDtmtXem7xjEYp4Gg9XDZc8x'
  },
  cromeleons: {
    title: 'Cromeleons',
    address: '0xf004d2f46e177eC8Ffc7c7c7f2623E212b450920',
    testnetAddress: '0x85631f65e1725cdb6DdFE4a85D8c5c6d02674852',
    thumbnail: 'cromeleons.png',
    image: 'cromeleons.gif',
    shortDescription: '2,222 NFTs with royalty sharing, NFT Staking, and a DAO.',
    description: 'CROmeleon’s are a unique and limited collection of 2,222 NFTs that deliver utility and a bold sense of personality.<br><br>100% of royalties are given back to holders granting them the ability to earn passive income. By Owning a CROmeleon, you are also granted the luxury of being able to vote on certain proposals highlighting the governance of our NFT as well as the ability to have exclusive access to the Gen 2 mint!<br/><br/>We are also the first Cronos NFT project to use HD macro imaging for the scale patterns of our artwork. The scales seen in our art are from real-life chameleons! Staking will go live early and a floor-sweeping treasury will be activated post-mint!',
    abi: cromeleons.abi,
    launchDate: 'February 15, 2022 18:30:00 UTC',
    soldOut: true,
    hidden: true,
    max: 20,
    price: 250,
    discountPrice: 200,
    maxTokens: 2222,
    cid: 'QmPycSCFZ4Qtrz652pQHxXnTd6zCgyULJdVTJTYZcMWZDc'
  }
}



import cromeleons from './Cromeleons.json'
import looters from './Looters.json'
import cronosfoxelite from './CronosFoxElite.json'
import croflips from './Croflips.json'
import crougarx from './CrougarX.json'
import scarecro from './ScareCRO.json'
import handpunks from './Handpunks.json'
import zombabies from './Zombabies.json'
import cronogoddesses from './CronoGoddesses.json'
import lucky from './Lucky.json'
import cropets from './CroPets.json'
import cronosbotsociety from './CBS.json'
import chad from './Chad.json'
import crodoges from './CroDoges.json'
import babycroge from './Croge.json'
import croarmy from './CROARMY.json'
import braindeadmonkeys from './BDM.json'
import cpu from './CPU.json'
import zoonft from './Zoo.json'
import viking from './Viking.json'
import cropunks from './CroPunks.json'
import armyburns from './Burns.json'
import bullishbeavers from './Bullish.json'
import toughlions from './TL.json'
import armysoldier from './Soldier.json'
import islandthunder from './Thunder.json'
import sexyseacows from './SSC.json'
import electricvibrations from './EV.json'
import diamond from './Diamond.json'
import anw from './ANW.json'
import crodeo2 from './Crodeo2.json'
import cromad from './Cromad.json'
import bandits from './Bandits.json'
import chibis from './Chibis.json'
import alienft2 from './ALIENFT2.json'
import coalition from './Coalition.json'
import bingo from './Bingo.json'
import connections from './Connections.json'
import bbecc from './BBECC.json'
import ballin from './Ballin.json'
import mafia from './MAFIA.json'
import cd2 from './CBZ.json'
import newamerica from './NEWAMERICA.json'
import cbz from './CBZ.json'
import croadz from './CROADZ.json'
import cdl from './CDL.json'
import ttg from './TTG.json'
import blackcat from './Blackcat.json'
import cropetlady from './CROPETLADY.json'
import cbzraffle from './CBZRAFFLE.json'
import rosescro from './ROSESCRO.json'
import zyzznft from './ZYZZNFT.json'
import mogulproject from './mogulproject.json'
import nftbetting from './NFTBetting.json'
import yardflamingos from './YardFlamingos.json'
import sexypixelcows from './SexyPixelCows.json'

export default ContractData;
